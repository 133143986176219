module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7F4ZPTD4L6"],"gtagConfig":{"send_page_view":true},"pluginConfig":{"respectDNT":true,"origin":"https://megaspell.net/"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Megaspell","short_name":"Megaspell","start_url":"https://megaspell.net/","background_color":"#fff","theme_color":"#00FF99","display":"minimal-ui","icon":"src/Megaspell_Icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"045b060119c96fbb29bdbe2277489bbb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
